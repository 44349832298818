import React, { useState } from "react"
import { useSelector } from "react-redux"

import Layout from "../../components/Layout/LayoutPublic/LayoutPublic"
import { useContentfulForm } from "../../contenful/querys/Forms"
import { FormGenerator } from "../../components/Forms/FormGenerator/FormGenerator"
import { ThankYou } from "../../components/Forms/ThankYou/ThankYou"

const ContactUs = ({ data: { contentfulPaginaContactoAgente } }) => {

	if (!contentfulPaginaContactoAgente) return null

	const {
		banner_title,
		subtitle_banner,
		title_liftit_can_you,
		liftit_you_can_items,
		general_communication,
		technical_support,
		banner_imagen
	} = contentfulPaginaContactoAgente

	const [thankYou, setThankYou] = useState(false)

	const { languageISO639 } = useSelector(state => state.language)
	const contentfulModalNewLead = useContentfulForm(languageISO639, "form_contactar_agente")

	return (
		<Layout>
			<div className="ContactUs">
				<div className="ContactUs__container">
					<img src={banner_imagen} className="video--banner" alt="banner-liftit" />
					<div className="back" />
					<div className="ContactUs__container--left">
						<h1>{banner_title}</h1>
						<p>{subtitle_banner}</p>
						<div className="cont">
							<h2>{title_liftit_can_you}</h2>
							<ul>
								{liftit_you_can_items?.map((item, index) =>
									<li key={index}><span>.</span>{item}</li>
								)}
							</ul>
						</div>
						<div>
							<h4>{general_communication.title}</h4>
							<p>{general_communication.description.description}</p>
						</div>
						<div>
							<h4>{technical_support.title}</h4>
							<p>{technical_support.description.description}</p>
						</div>
					</div>
					<div className="ContactUs__container--right">
						<div className="form">
							<h3>{banner_title}</h3>

							{thankYou &&
								<ThankYou handlerThankYou={setThankYou} />
							}
							{!thankYou && 
								<FormGenerator handlerThankYou={setThankYou} dropdowns={contentfulModalNewLead.dropdown} />
							}
						</div>
					</div>
				</div>
			</div>
		</Layout>
	)
}

export const pageQuery = graphql`
  query PageContacUs($locale: String) {
    contentfulPaginaContactoAgente(language: { language: { eq: $locale } }) {
      banner_title
			subtitle_banner
			title_liftit_can_you
			liftit_you_can_items 
			banner_imagen
			general_communication {
				description {
					description
				}
				title
			}
			technical_support {
				description {
					description
				}
				title
			}
    }
  }
`

export default ContactUs
