import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Multimedia } from "../../Multimedia/Multimedia";
import { navigatePage } from "../../../@common/utils/navigate"

export const ThankYou = ({ handlerClose, handlerThankYou }) => {

	const { t } = useTranslation()

	const goToHome = () => {
		navigatePage("/")
		if(handlerClose)
			handlerClose()
		handlerThankYou(false)
	}

	return (
		<div className="ThankYou--form">
			<Multimedia
				className={"video-pc"}
				src={"https://storage.googleapis.com/assets-contentful/development/v1/operadores/computador-fondo-blanco.mp4"}
				interval={4000}
			/>
			<h1 className="thanks">{t('form.thanks_signup.title')}</h1>
			<span className="text">{t('form.thanks_signup.description')}</span>
			<div className="cont-btn">
				<button className="btn btn--primary" onClick={goToHome}>{t('button.goToHome')}</button>
			</div>
		</div>
	)
}