import React, { useEffect, useState } from "react"
import ReactPlayer from "react-player"

import mapa from "../../assets/images/back-mapa.jpg";

export const Multimedia = ({
  id,
  src,
  hover,
  autoPlay = true,
  playbackRate,
  interval,
  className,
  poster,
  ...props
}) => {

  const [customPlayBack, setCustomPlayBack] = useState()
  const [isPlaying, setIsPlaying] = useState(false)

  useEffect(() => {
    if(id && hover){
      document.getElementById(id)
        .addEventListener("mouseenter", () => {
          setIsPlaying(true)
        })

      document.getElementById(id)
        .addEventListener("mouseout", () => {
          setCustomPlayBack(1.7)
        })
    }
  }, [])

  const onEndedVideo = () => {
    setIsPlaying(false)
    setCustomPlayBack()
    if (interval) setTimeout(() => setIsPlaying(true), interval)
  }

  if(!src)
    return null

  if (src.includes("mp4"))
    return (
      <ReactPlayer
        id={id}
        url={src}
        className={className}
        playing={isPlaying}
        onEnded={onEndedVideo}
        controls={false}
        muted
        playsInline
        config={{
          file: {
            attributes: {
              autoPlay,
              muted: true,
              poster: poster || undefined,
              playsInline: true
            },
          },
        }}
      />
    )

  return <img src={mapa} {...props} alt="multimedia"/>
}
